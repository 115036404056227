@-webkit-keyframes bg-scrolling-reverse {
    100% {
      background-position: 50px 50px;
    }
  }
  @-moz-keyframes bg-scrolling-reverse {
    100% {
      background-position: 50px 50px;
    }
  }
  @-o-keyframes bg-scrolling-reverse {
    100% {
      background-position: 50px 50px;
    }
  }
  @keyframes bg-scrolling-reverse {
    100% {
      background-position: 50px 50px;
    }
  }
  @-webkit-keyframes bg-scrolling {
    0% {
      background-position: 50px 50px;
    }
  }
  @-moz-keyframes bg-scrolling {
    0% {
      background-position: 50px 50px;
    }
  }
  @-o-keyframes bg-scrolling {
    0% {
      background-position: 50px 50px;
    }
  }
  @keyframes bg-scrolling {
    0% {
      background-position: 50px 50px;
    }
  }
 .industriesContainer{
    background: url("../../asset/hexagon.png") repeat 0 0;
    -webkit-animation: bg-scrolling-reverse 3s infinite;
    /* Safari 4+ */
    -moz-animation: bg-scrolling-reverse 3s infinite;
    /* Fx 5+ */
    -o-animation: bg-scrolling-reverse 3s infinite;
    /* Opera 12+ */
    animation: bg-scrolling-reverse 3s infinite;
    /* IE 10+ */
    -webkit-animation-timing-function: linear;
    -moz-animation-timing-function: linear;
    -o-animation-timing-function: linear;
    animation-timing-function: linear;
    
 } 
 
.aboutBg{
  
}
.clientbox{
  background-image: url("../../asset/clientsBackground.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  
}




@media screen and (min-width:1800px) {
  .industryCardImg{
    width: 130px;
    height: 130px;
  }
  .industriesContainer{
    padding: 60px 80px;
  }
.aboutHomeContainer{
    padding: 60px 180px;
}
.testimonialMainBox{
    padding: 50px 0px;
    padding-left: 120px;
}
.numbersMainBox{
    padding: 80px 120px;
}
.clientbox{
    padding: 60px 250px;

}
.aboutContent{
    font-size: 1rem;
}

}
@media screen and (min-width:1400px) and (max-width:1750px) {
  .industryCardImg{
    width: 100px;
    height: 100px;
  }
  .industriesContainer{
    padding: 60px 60px;
  }
    .aboutHomeContainer{
        padding: 60px 120px;
    }
    .testimonialMainBox{
        padding: 50px 0px;
        padding-left: 120px;
    }
    .numbersMainBox{
        padding: 60px 80px;
    }
    .clientbox{
        padding: 60px 200px;
    }
    .aboutContent{
        font-size: .8rem;
    }
}
@media screen and (min-width:1000px) and (max-width:1400px) {
    .industryCardImg{
      width: 100px;
      height: 100px;
    }
    .testimonialMainBox{
        padding: 50px 0px;
        padding-left: 120px;
    }
    .industriesContainer{
      padding: 60px 40px;
    }
    .aboutHomeContainer{
        padding: 60px 70px;
    }
    .clientbox{
        padding: 60px 140px;
    }
}
@media screen and (max-width:500px) {
  .aboutContent{
    font-size: .7rem;
}
.testimonialMainBox{
  padding: 50px 10px;
  
}
.aboutHomeContainer{
  padding: 40px 30px;
}
.numbersMainBox{
  padding: 30px 10px;
}
.clientbox{
  padding: 30px 30px;
}
}