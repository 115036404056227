.menuText1{
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333;
    background-color: #E7DCC7;
    clip-path: polygon(8% 0%, 92% 0%, 100% 50%, 92% 100%, 8% 100%, 0% 50%);
    transition: background-color 0.3s, color 0.3s;
    white-space: nowrap;
    font-size: 13px;
    font-weight: 500;
    width: 130px;
    text-align: center !important;
    height: 40px;
    margin-top: 20px;
    text-transform: uppercase;
}
.menuText1:hover,.menuText2:hover,.subMenuText1:hover,.subMenuText2:hover{
    background-color: red;
}
.subMenuText1{
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333;
    background-color: #FEDDD8;
    clip-path: polygon(8% 0%, 92% 0%, 100% 50%, 92% 100%, 8% 100%, 0% 50%);
    transition: background-color 0.3s, color 0.3s;
    white-space: nowrap;
    font-size: 12px;

    width: 160px;
    text-align: center !important;
    height: 40px;
    margin-top: 0px;
    text-transform: uppercase;
}

.menuText2{
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333;
    background-color: #BFBFBF;
    clip-path: polygon(8% 0%, 92% 0%, 100% 50%, 92% 100%, 8% 100%, 0% 50%);
    transition: background-color 0.3s, color 0.3s;
    white-space: nowrap;
    font-size: 13px;
    font-weight:500;
    width: 130px;
    margin-top: -25px;
    text-align: center !important;
    height: 40px;
    text-transform: uppercase;
}
.mainBtn{
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333;
    background-image: linear-gradient(to right, #bfbfbf,#E7DCC7);
    clip-path: polygon(6.5% 0%, 92.5% 0%, 100% 50%, 92.5% 100%, 6.5% 100%, 0% 50%);
    transition: background-color 0.3s, color 0.3s;
    white-space: nowrap;
    font-size: 15px;
    
    font-weight: bold;
    width: 200px;
   margin-left: 30px;
    text-align: center !important;
    height: 40px;
    text-transform: uppercase;
}

.mainMenuItem{
    background-color: transparent !important;
}



@media screen and (min-width:1800px) {
    .navLogo{
        margin-bottom: 1rem;
        width: 320px;
    }
  
  }
  @media screen and (min-width:1400px) and (max-width:1750px) {
    .navLogo{
        margin-bottom: 0.5rem;
        width: 300px;
    }
  }
  @media screen and (min-width:1000px) and (max-width:1400px) {
    .navLogo{
        margin-bottom: 0.5rem;
        width: 100%;
    }
  }
  