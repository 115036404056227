.careerBreadcrubContainer {
    background-image: url("../../asset/Contactus.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;

}

/* Background Animation */
.bg-pattern {
    background: radial-gradient(
          circle farthest-side at 0% 50%,
          #fff 23.5%,
          rgba(255, 255, 255, 0) 0
        )
        21px 30px,
      radial-gradient(
          circle farthest-side at 0% 50%,
          #e3e3e3 24%,
          rgba(255, 255, 255, 0) 0
        )
        19px 30px,
      linear-gradient(
          #fff 14%,
          rgba(255, 255, 255, 0) 0,
          rgba(255, 255, 255, 0) 85%,
          #fff 0
        )
        0 0,
      linear-gradient(
          150deg,
          #fff 24%,
          #e3e3e3 0,
          #e3e3e3 26%,
          rgba(255, 255, 255, 0) 0,
          rgba(255, 255, 255, 0) 74%,
          #e3e3e3 0,
          #e3e3e3 76%,
          #fff 0
        )
        0 0,
      linear-gradient(
          30deg,
          #fff 24%,
          #e3e3e3 0,
          #e3e3e3 26%,
          rgba(255, 255, 255, 0) 0,
          rgba(255, 255, 255, 0) 74%,
          #e3e3e3 0,
          #e3e3e3 76%,
          #fff 0
        )
        0 0,
      linear-gradient(90deg, #e3e3e3 2%, #fff 0, #fff 98%, #e3e3e3 0%) 0 0 #ffffff;
    background-size: 40px 60px;
    animation: movePattern 10s linear infinite;
}