/* Background Animation */
.bg-pattern {
  background: radial-gradient(
        circle farthest-side at 0% 50%,
        #fff 23.5%,
        rgba(255, 255, 255, 0) 0
      )
      21px 30px,
    radial-gradient(
        circle farthest-side at 0% 50%,
        #e3e3e3 24%,
        rgba(255, 255, 255, 0) 0
      )
      19px 30px,
    linear-gradient(
        #fff 14%,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 0) 85%,
        #fff 0
      )
      0 0,
    linear-gradient(
        150deg,
        #fff 24%,
        #e3e3e3 0,
        #e3e3e3 26%,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 0) 74%,
        #e3e3e3 0,
        #e3e3e3 76%,
        #fff 0
      )
      0 0,
    linear-gradient(
        30deg,
        #fff 24%,
        #e3e3e3 0,
        #e3e3e3 26%,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 0) 74%,
        #e3e3e3 0,
        #e3e3e3 76%,
        #fff 0
      )
      0 0,
    linear-gradient(90deg, #e3e3e3 2%, #fff 0, #fff 98%, #e3e3e3 0%) 0 0 #ffffff;
  background-size: 40px 60px;
  animation: movePattern 10s linear infinite;
}

@keyframes movePattern {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 60px 40px;
  }
}


@media screen and (min-width:1400px) and (max-width:1700px) {
  .aboutContent{
    padding: 10px 10px;
    font-size: 0.8rem;
  }
}


@media screen and (min-width:1000px) and (max-width:1400px) {
  .aboutInfoContainer{
    padding: 40px 40px;
  }
}






